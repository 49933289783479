import * as THREE from 'three';
import gsap from 'gsap';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Raycaster, Vector2 } from 'three';

/**
 * Debug
 */

const parameters = {
    materialColor: '#ffeded'
}

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();

/**
 * Objects
 */
// Texture
const textureLoader = new THREE.TextureLoader();
const gradientTexture = textureLoader.load('textures/gradients/3.jpg');
gradientTexture.magFilter = THREE.NearestFilter;

// Objects
const objectsDistance = 4;
const sectionMeshes = [];

const loader = new GLTFLoader();
const raycaster = new Raycaster();
const mouse = new Vector2();

// Load the first model
loader.load(
    './models/Head.glb',
    (gltf) => {
        const model1 = gltf.scene;
        model1.position.set(1.25, -objectsDistance * 0.2, 0);
        model1.scale.set(70, 70, 70); // Unique scale factor
        scene.add(model1);
        sectionMeshes.push(model1);
    },
    undefined,
    (error) => {
        console.error(error);
    }
);

// Load the second model
loader.load(
    './models/astronaut_from_the_.glb',
    (gltf) => {
        const model2 = gltf.scene;
        model2.position.set(-2, -objectsDistance * 1, 0);
        model2.scale.set(2.5,2.5,2.5); // Unique scale factor
        scene.add(model2);
        sectionMeshes.push(model2);
    },
    undefined,
    (error) => {
        console.error(error);
    }
);

// Load the third model with animation
let spaceshipMixer;
loader.load(
    './models/SpaceShip.glb',
    (gltf) => {
        const model3 = gltf.scene;
        model3.position.set(1.75, -objectsDistance * 2, 0);
        model3.scale.set(5.8, 5.8, 5.8); // Unique scale factor
        scene.add(model3);
        sectionMeshes.push(model3);

        // Setup animation
        spaceshipMixer = new THREE.AnimationMixer(model3);
        gltf.animations.forEach((clip) => {
            spaceshipMixer.clipAction(clip).play();
        });
    },
    undefined,
    (error) => {
        console.error(error);
    }
);

/**
 * Lights
 */
const directionalLight = new THREE.DirectionalLight('#ffffff', 3);
directionalLight.position.set(1, 1, 0);
scene.add(directionalLight);

/**
 * Particles
 */
// Geometry
const particlesCount = 550;
const positions = new Float32Array(particlesCount * 3);

for(let i = 0; i < particlesCount; i++) {
    positions[i * 3 + 0] = (Math.random() - 0.5) * 10;
    positions[i * 3 + 1] = (Math.random() - 0.5) * objectsDistance * (sectionMeshes.length + 7);
    positions[i * 3 + 2] = (Math.random() - 0.5) * 10;
}

const particlesGeometry = new THREE.BufferGeometry();
particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));

// Material
const particlesMaterial = new THREE.PointsMaterial({
    color: parameters.materialColor,
    sizeAttenuation: true,
    size: 0.03
});

// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial);
scene.add(particles);

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
};

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
});

/**
 * Camera
 */
// Group
const cameraGroup = new THREE.Group();
scene.add(cameraGroup);

// Base camera
const camera = new THREE.PerspectiveCamera(35, sizes.width / sizes.height, 0.1, 100);
camera.position.z = 6;
cameraGroup.add(camera);

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

/**
 * Scroll
 */
let scrollY = window.scrollY;
let currentSection = 0;

window.addEventListener('scroll', () => {
    scrollY = window.scrollY;
    const newSection = Math.round(scrollY / sizes.height);
/*
    if (newSection != currentSection) {
        currentSection = newSection;

        gsap.to(
            sectionMeshes[currentSection].rotation,
            {
                duration: 1.5,
                ease: 'power2.inOut',
                x: '+=6',
                y: '+=3',
                z: '+=1.5'
            }
        );
    }*/
});

/**
 * Cursor
 */
const cursor = {};
cursor.x = 0;
cursor.y = 0;

window.addEventListener('mousemove', (event) => {
    cursor.x = event.clientX / sizes.width * 2 - 1;
    cursor.y = -(event.clientY / sizes.height * 2 - 1);
    mouse.set(cursor.x, cursor.y);
    raycaster.updateMatrixWorld();
    raycaster.setFromCamera(mouse, camera);

    // Check intersections
    const intersects = raycaster.intersectObject(sectionMeshes[2]);
    if (intersects.length > 0) {
        if (spaceshipMixer) {
            spaceshipMixer.clipAction(spaceshipMixer.clipAction).play();
        }
    }
});

/**
 * Animate
 */
const clock = new THREE.Clock();
let previousTime = 0;

const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;

    // Animate camera
    camera.position.y = -scrollY / sizes.height * objectsDistance;

    const parallaxX = cursor.x * 0.5;
    const parallaxY = -cursor.y * 0.5;
    cameraGroup.position.x += (parallaxX - cameraGroup.position.x) * 5 * deltaTime;
    cameraGroup.position.y += (parallaxY - cameraGroup.position.y) * 5 * deltaTime;

    // Animate meshes
    for (const mesh of sectionMeshes) {
        mesh.rotation.x += deltaTime * 0.0;
        mesh.rotation.y += deltaTime * 0.1;
    }

    // Update animation mixer
    if (spaceshipMixer) {
        spaceshipMixer.update(deltaTime);
    }

    // Render
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
}

tick();
